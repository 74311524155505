import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入全局样式
import 'default-passive-events'//解决elment ui在浏览器的警示提示
import "./assets/iconfont/iconfont.css"; //引入阿里巴巴矢量图标库
import '@/assets/styles/common.css'
import '@/assets/styles/override.css'
// 在main.js中全局引入echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts
// 引入elementUi
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/element.js'
Vue.use(ElementUI)
Vue.config.productionTip = false
// 导入video.js
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import VideoPlayer from 'vue-video-player'
// 引入公共方法
import util from './components/util'
Vue.prototype.$u=util

Vue.use(VideoPlayer)
require('vue-video-player/src/custom-theme.css')
// 全局配置每个页面的搜索关键词
router.beforeEach((to,from,next)=>{
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
})
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
