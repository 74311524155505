export default {

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
            type: mime
        });
    },


    compressImage(file, config) {
        return new Promise((resolve, reject) => {
            let _URL = window.URL || window.webkitURL
            var img = new Image()
            img.src = _URL.createObjectURL(file)
            img.onload = function() {
                var that = this
                var w = that.width
                var h = that.height
                var scale = w / h
                w = config.width || config.height * scale
                h = config.height || config.width / scale
                var quality = 0.7 // 默认图片质量为0.7
                // 生成canvas
                var canvas = document.createElement('canvas')
                var ctx = canvas.getContext('2d')
                // 创建属性节点
                var anw = document.createAttribute('width')
                anw.nodeValue = w
                var anh = document.createAttribute('height')
                anh.nodeValue = h
                canvas.setAttributeNode(anw)
                canvas.setAttributeNode(anh)
                ctx.drawImage(that, 0, 0, w, h)
                if (config.quality && config.quality <= 1 && config.quality > 0) {
                    quality = config.quality
                }
                var base64 = canvas.toDataURL('image/*', quality)
                resolve(base64);
                // var blob = convertBase64UrlToBlob(base64)

            }
        })
    }




}