import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // 主界面路由
        {
            path: '/layout',
            name: 'layout',
            // redirect: {name: 'index'},
            component: () => import('./views/layout.vue'),
            children: [
                // 课程------------------------------------------------------
                {
                    path: '/index',
                    name: 'index',
                    meta: {title: '首页'},
                    component: () => import('./views/index/index.vue'),

                },

                // 班级管理tab--------------------------------------------
                {
                    path: '/hourDetial',
                    name: 'indhourDetialex',
                    meta: {title: '课程详情'},
                    component: () => import('./views/index/hourDetial.vue'),

                },
                // 班级管理
                {
                    path: '/classBa',
                    name: 'classBa',
                    component: () => import('./views/classBa/classBa.vue'),
                },
                // 班级管理-班级详情
                {
                    path: '/classDetail',
                    name: 'classDetail',
                    component: () => import('./views/classBa/classDetail.vue'),
                },
                // 班级管理-班级审核
                {
                    path: '/classcheck',
                    name: 'classcheck',
                    component: () => import('./views/classBa/classcheck.vue'),
                },
                // 班级管理-导出学生
                {
                    path: '/tolead',
                    name: 'tolead',
                    component: () => import('./views/classBa/tolead.vue'),
                },
                // 作品管理------------------------------------------------------
                {
                    path: '/course',
                    name: 'course',
                    component: () => import('./views/course/course.vue'),
                },
                {
                    path: '/works',
                    name: 'works',
                    component: () => import('./views/course/works.vue'),
                },
				{
				    path: '/workdetail',
				    name: 'workdetail',
				    component: () => import('./views/course/workdetail.vue'),
				},
                {
                    path: '/jobdetail',
                    name: 'jobdetail',
                    component: () => import('./views/course/jobdetail.vue'),
                },
                {
                    path: '/upworks',
                    name: 'upworks',
                    component: () => import('./views/course/upworks.vue'),
                },
                // 相册管理
                {
                    path: '/photoList',
                    name: 'photoList',
                    component: () => import('./views/photoList/photoList.vue'),
                },
                {
                    path: '/photodetali',
                    name: 'photodetali',
                    component: () => import('./views/photoList/photodetali.vue'),
                },
                {
                    path: '/upphoto',
                    name: 'upphoto',
                    component: () => import('./views/photoList/upphoto.vue'),
                },
            ]
        },
        // 官网/登录/注册路由
        {
            path: '/',
            name: 'login',
            component: () => import('./views/index/login.vue'),
        },
        // 关于我们路由
        {
            path: '/about',
            name: 'about',
            component: () => import('./views/after/about.vue'),
        },
        // 在线客服路由
        {
            path: '/service',
            name: 'service',
            component: () => import('./views/after/service.vue'),
        },
        // 帮助中心路由
        {
            path: '/help',
            name: 'help',
            component: () => import('./views/after/help.vue'),
        },
        // 联系我们路由
        {
            path: '/relation',
            name: 'relation',
            component: () => import('./views/after/relation.vue'),
        },

    ]
});
// router.beforeEach((to, from, next) => {
//     // chrome
//     document.body.scrollTop = 0
//     // firefox
//     document.documentElement.scrollTop = 0
//     // safari
//     window.pageYOffset = 0
//     next()
// })

export default router;